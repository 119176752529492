$iesa-default-text-color: #000;
$iesa-color-primary: #032EE0;
$iesa-color-secondary: #1441FF;
$iesa-color-footer: #F4F2EF;
$iesa-activity-icon: #133CED;
$iesa-background-light: #F8F9FD;
$iesa-background-dark: #AFAFAF;
$iesa-border-color: #DFE4F7;
$iesa-input-border-color: #000;
$iesa-input-background: #E4E8F5;
$iesa-header-color: #FCFCFF;
