* {
    box-sizing: border-box;
}

html {
    line-height: unset;
}

img {
    display: block;
}

a {
    text-decoration: none;
    color: unset;
}

h1, h2, h3, p {
    margin: 0;
}

button {
    border: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

textarea:focus, input:focus{
    outline: none;
}

.swiper, .swiper-wrapper {
    z-index: 0 !important;
}