@import '~normalize.css';
@import './styles_config.scss';
@import './styles_fonts.scss';
@import './styles_reset.scss';
@import './styles_mixins.scss';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';

html {
    color: $iesa-default-text-color;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    min-height: 100vh;
}

.disable-scroll {
    overflow: hidden;
}

.active {
    color: $iesa-color-primary;
}

.iesa-width {
    max-width: 1720px;
    margin: 0 auto;
}

.full-width {
    max-width: 1920px;
    margin: 0 auto;
}

p {
    line-height: 24px;
    font-size: 16px;
    @include mobile {
        font-size: 14px;
        line-height: 22px;
    }
}

.only-mobile {
    display: none;
    @include mobile {
        display: block;
    }
}
