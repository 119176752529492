$breakpoint-mobile: 767px;
$breakpoint-tablet: 1023px;
$breakpoint-desktop: 1919px;

@mixin mobile {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: $breakpoint-desktop) {
        @content;
    }
}
